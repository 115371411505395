/*=================== contact banner start ====================*/

.contact-info{
  margin-bottom: 25px;

  &__icon{
    margin-right: 20px;

    i,span{
      color: #8f9195;
      font-size: 27px;
    }
  }

  .media-body{

    h3{
      font-size: 16px;
      margin-bottom: 0;
      font-size: 16px;
      color: #2a2a2a;
      a{
        &:hover{
          color: $btn_bg;
        }
      }
    }

    p{
      color: #8a8a8a;
    }
  }
}

/*=================== contact banner end ====================*/


/*=================== contact form start ====================*/
.contact-title{
  font-size: 27px;
  font-weight: 600;
  margin-bottom: 20px;
}

.form-contact{

  label{
    font-size: 14px;
  }
  .form-group{
    margin-bottom: 30px;
    .button-contactForm{
      padding: 5px 5px 5px 20px;
    }
    .btn_1{
      margin-bottom: -30px;
      @extend %custom_btn_bg_3;
      border: 1px solid #999999;
      border-radius: 0;
      color: #000;
      display: flex;
      align-items: center;
      @media #{$tab}{
        margin-bottom: 20px;
      }
      i{
        color: #000;
        width: 50px;
        height: 50px;
        @extend %custom_btn_bg_1;
        display: inline-block;
        line-height: 50px;
        margin-left: 20px;
        background-size: 200% auto;
        @include transform_time(0.5s);
      }
      &:hover{
        border: 1px solid $btn_bg;
        color: $btn_bg !important;
        i{
          color: $btn_bg;
          color: #fff !important;
        }
      }
    }
  }

  .form-control{
    border: 1px solid #f0e9ff;
    border-radius: 5px;
    height: 48px;
    padding-left: 18px;
    font-size: 13px;
    background: transparent;

    &:focus{
      outline: 0;
      box-shadow: none;
    }

    &::placeholder{
      font-weight: 300;
      color: #999999;
    }
  }

  textarea{
    border-radius: 12px;
    height: 100% !important;
  }

  // button{
  //   border: 0;
  // }
}

@media #{$small_mobile}{
  .contact-section{
    .btn_1 {
      margin-bottom: 0px !important;
      margin-top: 0px !important;
    }
  }
}
@media #{$large_mobile}{
  .contact-section{
    .btn_1 {
      margin-bottom: 0px !important;
      margin-top: 0px !important;
    }
  }
}
@media #{$tab_device}{
  .contact-section{
    .btn_1 {
      margin-bottom: 0px !important;
      margin-top: 0px !important;
    }
  }
}
@media #{$medium_device}{
  .contact-section{
    .btn_1 {
      margin-bottom: 0px !important;
      margin-top: 0px !important;
    }
  }
}
/*=================== contact form end ====================*/

/* Contact Success and error Area css
============================================================================================ */


.modal-message {
    .modal-dialog {
        position: absolute;
        top: 36%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) !important;
        margin: 0px;
        max-width: 500px;
        width: 100%;
        .modal-content {
            .modal-header {
                text-align: center;
                display: block;
                border-bottom: none;
                padding-top: 50px;
                padding-bottom: 50px;
                .close {
                    position: absolute;
                    right: -15px;
                    top: -15px;
                    padding: 0px;
                    color: #fff;
                    opacity: 1;
                    cursor: pointer;
                }
                h2 {
                    display: block;
                    text-align: center;
                    padding-bottom: 10px;
                }
                p {
                    display: block;
                }
            }
        }
    }
}


@media #{$tab}{
  .contact-section{
    .contact_form{
      .btn_1{
        margin-bottom: 60px !important;
      }
    }
  }
  
}
@media #{$medium_device}{

}
