/**************** banner part css start ****************/
.banner_part{
    position: relative;
    background-image: url(../img/banner_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 1050px;
    background-color: $btn_hover;
    @media #{$tab}{
        height: 700px;
    }
    &:after{
        position: absolute;
        content: "";
        right: 0;
        bottom: -132px;
        width: 100%;
        height: 100%;
        background-image: url(../img/banner_overlay.svg);
        background-position: top;
        background-repeat: no-repeat;
        z-index: -1;
        background-size: cover;
    }
    .banner_text{
        padding-top: 235px;
        h5{
            font-size: 36px;
            text-transform: uppercase;
            font-weight: 500;
            color: $white_color;
            font-family: $font_stack_3;
            margin-bottom: 34px;
            @media #{$tab}{
                font-size: 25px;
                margin-bottom: 20px;
            }
        }
        h1{
            font-size: 66px;
            text-transform: capitalize;
            font-weight: 700;
            line-height: 75px;
            color: $white_color;
            position: relative;
            z-index: 1;
            &:after{
                position: absolute;
                content: "";
                left: 0;
                bottom: -86px;
                width: 50%;
                height: 100%;
                background-image: url(../img/heading_bg.png);
                background-position: top;
                background-repeat: no-repeat;
                background-size: contain;
                z-index: -1;
            }
            @media #{$small_mobile}{
                font-size: 30px;
                margin-bottom: 15px;
                line-height: 1.3;
            }
            @media #{$large_mobile}{
                font-size: 30px;
                margin-bottom: 15px;
                line-height: 1.3;
            }
            @media #{$tab_device}{
                font-size: 40px;
                margin-bottom: 15px;
                line-height: 1.3;
            }
            @media #{$medium_device}{
                font-size: 40px;
                margin-bottom: 15px;
                line-height: 1.4;
            }
            span{
                color: $btn_bg;
            }
        }
    }
}
  
/**************** hero part css end ****************/
