// variable scss
@import "variables";

// mixin scss
@import "mixins";
@import "extends";
// default scss
@import "common";

// button scss
@import "button";

@import "blog";
@import "single_blog";
// body scss
@import "menu";
@import "banner";
@import "search";
@import "feature_part";
@import "about_us";
@import "popular_place";
@import "place_details";
@import "tour_package";
@import "cta_part";
@import "footer";
@import "elements";
@import "blog_part";
@import "copyright_part";
@import "contact";

// breadcrumb scss
@import "breadcrumb";














@import "footer";


