/**************** service_part css start ****************/
.feature_part {
  position: relative;
  z-index: 1;
  .feature_icon_1{
    position: absolute;
    left: 10%;
    top: 26%;

} 
.feature_icon_2{
    position: absolute;
    right: 10%;
    bottom: 26%;
}
.feature_icon_3{
    position: absolute;
    right: 40%;
    bottom: 40%;
}
  .feature_img{
    @media #{$small_mobile} {
      margin-bottom: 50px;
    }

    @media #{$large_mobile} {
      padding-left: 0;
    }
  }
   .feature_part_text {
      @media #{$small_mobile} {
        padding-left: 0;
      }
  
      @media #{$large_mobile} {
        padding-left: 0;
      }
  
      @media #{$tab_device} {
        padding-left: 0;
      }
  
      @media #{$medium_device} {
        padding-left: 0;
      }
  
      h2 {
        font-size: 35px;
        font-weight: 500;
        margin: 42px 0 30px;
        position: relative;
        text-transform: capitalize;
        @media #{$small_mobile} {
          font-size: 25px;
          margin-bottom: 10px;
          line-height: 35px;
          margin-top: 15px;
        }
  
        @media #{$large_mobile} {
          font-size: 25px;
          margin-bottom: 10px;
          line-height: 35px;
          margin-top: 20px;
        }
  
        @media #{$tab_device} {
          font-size: 25px;
          margin-bottom: 10px;
          line-height: 35px;
          margin-top: 20px;
        }
  
        @media #{$medium_device} {
          font-size: 28px;
          margin-bottom: 20px;
          line-height: 40px;
        }
      }
      p{
        margin-bottom: 13px;
      }
      .feature_part_text_iner{
        border: 1px solid #eeeeee;
        text-align: center;
        padding: 31px 15px 9px;
        @include transform_time(.5s);
        @media #{$tab}{
          padding: 20px 15px 15px;
          margin-bottom: 15px;
        }
        @media #{$medium_device}{
        
        }
        
        img{
          margin-bottom: 20px;
        }
        h4{
          font-size: 20px;
          font-weight: 500;
          font-family: $font_stack_2;
          margin-bottom: 0;
          
        }
        p{
          
        }
        &:hover{
          border: 1px solid $btn_color;
          background-color: $btn_color;
          h4{
            color: $white_color;
          }
          p{
            color: $white_color;
          }
        }
      }
  
    }
    .single_feature{
      margin: 0 11px;
      @media #{$tab} {
        margin: 0;
     }

     @media #{$medium_device} {
      margin: 0;
     }
      &:nth-child(2){
        margin-top: 60px;  
        @media #{$tab} {
          margin-top: 30px;
       }
  
       @media #{$medium_device} {
        margin-top: 30px;
       }
      }
    }
   .single_feature_part {
      padding: 40px 25px 31px;
      text-align: center;
      border-radius: 5px;
      box-shadow: 0px 3px 81px 0px rgba(216, 216, 216, 0.45);
      background-color: $white_color;
      @include transform_time(.6s);
      @media #{$tab} {
         padding: 25px 15px;
         margin-top: 25px;
      }

      @media #{$medium_device} {
         margin-top: 25px;
      }
      
      
      img {
         margin-bottom: 21px;
         height: 65px;
         @media #{$tab} {
            margin-bottom: 21px;
         }
   
         @media #{$medium_device} {
   
         }
         i{
            color: $icon_color;
            font-size: 24px;
            @include transform_time(.6s);
         }
      }
      h4 {
         font-weight: 600;
         font-size: 20px;
         margin-bottom: 22px;
         @media #{$small_mobile} {
            margin-bottom: 15px;
         }
   
         @media #{$large_mobile} {
            margin-bottom: 15px;
         }
   
         @media #{$tab_device} {
            margin-bottom: 15px;
         }
   
         @media #{$medium_device} {
   
         }
      }

   }
   span{
     font-style: italic;
     font-weight: 300;
     color: $black_color;
     line-height: 30px;
     font-family: $font_stack_2;
     margin-bottom: 50px;
     display: inline-block;
   }
}
.single_feature_padding{
   padding-top: 140px;
   @media #{$small_mobile}{
      padding-top: 70px;
   }
   @media #{$large_mobile}{
      padding-top: 70px;
   }
   @media #{$tab_device}{
      padding-top: 70px;
   }
   @media #{$medium_device}{
      padding-top: 70px;
   }
}