/************** search css start ***************/
.search_your_country{
    max-width: 1690px;
    margin: 0 auto;
    background-color: $white_color;
    box-shadow: -1.717px 8.835px 85.56px 6.44px rgba(170, 170, 170, 0.29);
    padding: 165px 0 100px;
    position: relative;
    margin-top: -254px;
    @media #{$tab}{
        padding: 70px 0;
        margin-top: -70px;
    }
    .container-fluid{
        max-width: 980px;
        @media #{$tab}{
            max-width: 90%;
        }
    }
    .search_form{
        background-color: $primary_color;
        padding: 80px 95px;
        margin-bottom: 50px;
        color: $white_color;
        position: absolute;
        width: 100%;
        top: -265px;
        @media #{$tab}{
            padding: 5px;
            left: 0;
            margin-top: 130px
        }
        @media #{$medium_device}{
            left: 0;
        }
        .search_btn{
            background-color: $btn_color;
            display: inline-block;
            padding: 19px 60px;
            text-transform: capitalize;
            color: #fff;
            line-height: 22px;
            width: 100%;
            text-align: center;
            
        }
        .search_form_iner{
            display: flex;
            .custom_select {
                width: 50%;
                border: 0px solid transparent;
                border-radius: 0;
                color: #c3c3c3;
                height: 60px;
                padding-top: 11px;
                border-radius: 0;
                text-transform: capitalize;
                &:before{
                    position: absolute;
                    content: "";
                    right: 0;
                    top: 17px;
                    width: 1px;
                    height: 25px;
                    background-color: #c3c3c3;
                }
                &:last-child{
                    &:before{
                        width: 0;
                        height: 0;
                        background-color: transparent;
                    }
                }
                &:after{
                    height: 8px;
                    width: 8px;
                    margin-top: -3px;
                    right: 20px;
                }
                .list{
                    width: 100%;
                }
            }
        }
    }
    .single_donation_item{
        margin-top: 70px;
        @media #{$tab}{
            margin-top: 40px;
        }
        img{
            max-width: 27px;
        }
        h4 {
            font-size: 18px;
            text-transform: capitalize;
            margin: 35px 0 10px;
            @media #{$tab}{
                margin: 20px 0 10px;
            }
        }
        .read_btn{
            font-size: 17px;
            color: $black_color;
            font-family: $font_stack_3;
            text-transform: capitalize;
            display: inline-block;
            margin-top: 30px;
            @include transform_time(.5s);
            &:hover{
                color: $btn_color;
            }
            @media #{$tab}{
                margin-top: 15px;
            }
        }
    }
    .feature_icon_1{
        position: absolute;
        left: 10%;
        top: 26%;

    } 
    .feature_icon_2{
        position: absolute;
        right: 10%;
        bottom: 26%;
    }
}